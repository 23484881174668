exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-br-contact-success-index-js": () => import("./../../../src/pages/br/contact/success/index.js" /* webpackChunkName: "component---src-pages-br-contact-success-index-js" */),
  "component---src-pages-en-contact-success-index-js": () => import("./../../../src/pages/en/contact/success/index.js" /* webpackChunkName: "component---src-pages-en-contact-success-index-js" */),
  "component---src-pages-es-contact-success-index-js": () => import("./../../../src/pages/es/contact/success/index.js" /* webpackChunkName: "component---src-pages-es-contact-success-index-js" */),
  "component---src-pages-pt-contact-success-index-js": () => import("./../../../src/pages/pt/contact/success/index.js" /* webpackChunkName: "component---src-pages-pt-contact-success-index-js" */),
  "component---src-pages-uk-contact-success-index-js": () => import("./../../../src/pages/uk/contact/success/index.js" /* webpackChunkName: "component---src-pages-uk-contact-success-index-js" */),
  "component---src-templates-article-page-js": () => import("./../../../src/templates/article-page.js" /* webpackChunkName: "component---src-templates-article-page-js" */),
  "component---src-templates-automation-page-js": () => import("./../../../src/templates/automation-page.js" /* webpackChunkName: "component---src-templates-automation-page-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-category-page-js": () => import("./../../../src/templates/category-page.js" /* webpackChunkName: "component---src-templates-category-page-js" */),
  "component---src-templates-channel-manager-page-js": () => import("./../../../src/templates/channel-manager-page.js" /* webpackChunkName: "component---src-templates-channel-manager-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-management-fees-page-js": () => import("./../../../src/templates/management-fees-page.js" /* webpackChunkName: "component---src-templates-management-fees-page-js" */),
  "component---src-templates-pricing-page-js": () => import("./../../../src/templates/pricing-page.js" /* webpackChunkName: "component---src-templates-pricing-page-js" */),
  "component---src-templates-sef-tmt-fat-page-js": () => import("./../../../src/templates/sef-tmt-fat-page.js" /* webpackChunkName: "component---src-templates-sef-tmt-fat-page-js" */),
  "component---src-templates-staff-page-js": () => import("./../../../src/templates/staff-page.js" /* webpackChunkName: "component---src-templates-staff-page-js" */),
  "component---src-templates-statistics-page-js": () => import("./../../../src/templates/statistics-page.js" /* webpackChunkName: "component---src-templates-statistics-page-js" */)
}

